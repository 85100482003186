import React from 'react';
import { Link } from 'react-router-dom';

function ApplicationForm() {
    return (
        <section className="hero-area">
            <div className="banner-item" style={{ backgroundImage: "url(images/banner/banner.jpg)" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="banner-content-wrap">
                                <h1 className="banner-title wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="500ms">16. ETHOS ANKARA ULUSLARARASI TİYATRO FESTİVALİ TOPLULUK BAŞVURULARI BAŞLADI </h1>


                                <h3 className="wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="500ms"><span style={{ color: "white" }}>* Son başvuru tarihi 15 Ağustos 2024</span></h3>
                                <Link to="doc/16_ethos_application_form_tr.docx" target='_blank' className="banner-title wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="500ms"><span className='btn btn-success'>BAŞVURU FORMU</span> </Link>
                                <Link  to="doc/16_ethos_application_form_eng.doc" target='_blank' className="banner-title wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="500ms"><span className='btn btn-success'>APPLICATION FORM </span> </Link>
                                <div>
                                    <b>ANA TEMA: AŞK</b><br />
                                    Yüzyıllardır insana insanı anlatan tiyatro sanatının her ürününde  aşkın izlerini,  gölgelerini bulmak mümkün. Aşkla tanışanlar, bir şeye aşk ile bağlananların yolu da sanat ile kesişmektedir. Festivalimizin 16. yılını doldurmasının, gelenekselleşmesinin temelinde de tiyatro aşkı yatmaktadır.
                                    <br />
                                    Sözlü dönemlerin, destanlara, oyunlara dek var olan gerçekliğin en belirgin teması olan ve bir ağacın dalları gibi yurt sevgisinden, doğa sevgisine, yaşam sevgisinden, özgürlük aşkına kadar çeşitlenen düşünceyle, inançla bütünleşen aşk; 16. ETHOS Ankara Uluslararası Tiyatro Festivali’nde; oyunlar, atölyeler, sokak gösterileri ile Ankaralı izleyiciler ile buluşulacak. İyi Seyirler Ankara.
                                </div> 
                                </div>
                        </div>
                        <div className="col-lg-4 align-self-end">
                            <div className="banner-img">
                                <img src="images/16_ethos.jpeg" alt="" />

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    );
}

export default ApplicationForm;