import React from 'react'
import Footer from './components/Footer'
import SloganSection from './sections/SloganSection'
import Header from './components/Header'
import HeroSection from './sections/HeroSection'
import FestivalSection from './sections/FestivalSection'
import ProgramSection from './sections/ProgramSection'
import Program2023 from './sections/Program2023'
import TheatresSection from './sections/TheatresSection'
import SponsorSection from './sections/SponsorSection'
import ApplicationForm from './sections/ApplicationForm'




export default function Main() {
    return (
        <React.Fragment>
     
            <Header />
            <ApplicationForm/>
           
            {
                //   <SloganSection />
            
          
           // <Program2023 />
           // <SloganSection />
            
                //  <SponsorSection/>
                //  <TheatresSection/>
            }
            
          
            <FestivalSection />
           
            <Footer />
        </React.Fragment>
    )
}
